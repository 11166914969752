import './Contact.style.css'

import author from '../Assets/gabriel.jpg'

const Contact = () => {
  return (
    <div className="contact">
      <img className="rounded" src={author} width={100} height={100} />
      <ul>
        <li>Gabriel Mahé</li>
        <li>Developer</li>
        <li>Paris</li>
      </ul>
    </div>
  )
}

export default Contact
