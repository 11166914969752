import { Link, useLocation } from 'react-router-dom'
import './TopBar.style.css'

const TopBar = () => {
  const location = useLocation()
  return (
    <div className="topBar">
      <Link
        to={'/'}
        className={location.pathname == '/' ? 'link selected' : 'link'}
      >
        Blog
      </Link>
      <Link
        to={'/about'}
        className={location.pathname == '/about' ? 'link selected' : 'link'}
      >
        About
      </Link>
    </div>
  )
}

export default TopBar
