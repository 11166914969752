import './App.css'
import { Route, Routes } from 'react-router'
import MainPage from './MainPage'
import Contact from './Contact'
import TopBar from './TopBar'

import logo from './Assets/miravos_logo.png'

const App = () => {
  return (
    <div className="App">
      <div className="header">
        <img className="rounded" src={logo} width={100} height={100} />
        <h1 className="title">Miravos</h1>
      </div>
      <TopBar />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/about" element={<Contact />} />
      </Routes>
    </div>
  )
}

export default App
