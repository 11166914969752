import './MainPage.style.css'

import salt from '../Assets/salt.jpg'

const MainPage = () => {
  return (
    <div className="main">
      <div className="post">
        <img src={salt} />
        <div className="content">
          <h3>Salt desert at Mendoza</h3>
          <p>
            During my exchange in Argentina, I had the time to go around the
            country and to visit some stunning areas. The first one was Mendoza,
            a city close to the Andes mountain range. We rent a car and we went
            to this amazing salt desert. It was definitely not a famous one, as
            you can see on the picture nobody was there. We spent some time to
            fly the drone over an old train line.
          </p>
        </div>
      </div>
    </div>
  )
}

export default MainPage
